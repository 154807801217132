@font-face {
  font-family: 'Gacor';
  font-style: normal;
  font-weight: normal;
  src: local('Gacor'), url('fonts/Gacor.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Regular'), url('fonts/Roboto-Regular.woff') format('woff');
}
/** Default styles **/
body, span {
  font-family: 'Roboto-Regular';
}
body.home {
  background-color: #fff;
  background-image: url(img/bg-home.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
button.MuiButton-root {
  border-radius: 20px;
}

#demo-simple-select-label {
  padding: 30px 10px 0px 0px;
}
div#demo-simple-select {
  margin: 15px 21px 0 0;
}

.bold-text {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
.italic-text {
  font-style: italic;
}
/** /Default styles **/

.app-bar {
  background-color: #fff;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  color: #A133B1;
}

/** Fonts **/
.title-app, h1, h2, h3, h4, h5, h6 {
  font-family: 'Gacor';
  font-weight: normal;
}
.product.card .MuiTypography-h5 {
  font-family: 'Gacor';
  font-size: 0.8em;
  width: 270px;
  word-wrap: break-word;
}
/** /Fonts **/


/** TitleBar **/
.title-app {
  letter-spacing: 0.2em;
  margin: 0;
}
.title-app.subtitle {
  letter-spacing: 0.1em;
  margin-top: 22px;
}
.title-container {
  display: flex;
}
.MuiToolbar-regular .MuiTypography-colorPrimary {
  color: #e3e3e3;
}
/** /TitleBar **/


.logo-alacuenta {
  display: inline-block;
  float: left;
  padding: 10px 10px 10px 0;
}

.logo-alacuenta > img {
  height: 40px;
  width: 40px;
}

.align-center {
  text-align: center;
}

.align-evently {
  display: flex;
  justify-content: space-evenly;
}

.align-between {
  display: flex;
  justify-content: space-between;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.App-header {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.bottom-spacer {
  height: 90px;
}

.container {
  margin: 20px auto;
  width: 700px;
}

/** Restaurants searcher **/
.container-departamento-ciudad {
  margin: 200px auto;
  width: 540px;
}
.container-departamento-ciudad fieldset {
  border-color: #eee;
}
.container-departamento-ciudad h3,
.container-departamento-ciudad input,
.container-departamento-ciudad label,
.container-departamento-ciudad p,
.container-departamento-ciudad span {
  color: #eee;
}
.container-departamento-ciudad.looking {
  margin: 50px auto 0 auto;
  width: 500px;
}
.container-departamento-ciudad input.MuiInputBase-input {
  color: #eee;
}
/** /Restaurants searcher **/

.content {
  margin: 10px;
  padding: 5px;
}

.content h4{
  margin-left:20px;
}

.container-full {
  margin: 10px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.carousel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.menuButton {
  margin-right: 10px;
}

.MuiTypography-root.menu-item-desktop {
  margin: 0 15px;
}

.price-text {
  color: #0f0;
  font-weight: bold;
}

.price-text-white {
  color: #0a0;
  float: right;
  font-size: 1.2em;
  font-weight: bold;
}

/** Product Selector **/
div.MuiSnackbarContent-root {
  background-color: #fff;
  border: 4px solid #F5F5F5;
}
div.MuiSnackbarContent-root .MuiSnackbarContent-message {
  color: #333333;
}
div.MuiSnackbarContent-root .MuiSnackbarContent-message .price-text {
  font-size: 1.2em;
  color: #1e840f;
}
div.MuiSnackbarContent-root .MuiBadge-badge {
  background-color: #ccc;
  color: #000;
}
/** /Product Selector **/

.card {
  min-height: 400px;
  margin-top: 10px;
  width: 300px;
}
.product-description {
  height: 50px;
}
.title-category {
  background-color: #A133B1;
  color: #eee;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  width: 100%;
}
.title-category.expanded {
  background-color: #89009D;
}
.container-full > div > div:first-child > .title-category {
  border-radius: 20px 20px 0 0;
}
.container-full > div > div:last-child > .title-category {
  border-radius: 0 0 20px 20px;
}
.container-full > div > div:last-child > .title-category.expanded {
  border-radius: 0;
}
h3.title-category > span {
  padding: 10px;
  color: #fff;
}

.container-right {
  display: flex;
  flex-direction: row-reverse;
}
/** /Product Selector **/

/** Shopping Car **/
  /** Shopping car element **/
.shopping-car-element {
  background-color: #eee;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  margin-bottom: 1px;
  width: 100%;
}
.shopping-car-element > .content-left {
  display: flex;
}
.shopping-car-element > .content-left img {
  border-radius: 10px 0 0 10px;
  height: 110px;
}
.shopping-car-element > .content-left div {
  margin: 0 10px;
  padding-top: 10px;
}
.shopping-car-element > .content-left h5 {
  margin: 0;
}
.shopping-car-element > .content-right {
  display: flex;
  flex-direction: column;
}
.shopping-car-element > .content-right .delete-product {
  float: right;
}
  /** /Shopping car element **/
/** /Shopping Car **/

.title {
  flex-grow: 1;
}


/** Forms **/
.input-correct label, .input-correct .MuiInputBase-input {
  color: #0a0;
}
.input-correct .MuiInput-underline:before{
  border-color: #0a0;
}
.icon-input-correct {
  color: #0a0;
  margin-top: 20px;
  margin-right: 5px;
}
.custom-search-box {
  width: 100%;
}
/** /Forms **/

/** Menu Items **/
a.link-normal, li.link-normal {
  color: #333333;
}
a.link-active, li.link-active {
  color: #A133B1;
}
/** /Menu Items **/

/** Responsive design version **/
@media (max-width: 768px) {
  .bottom-spacer {
    height: 60px;
  }
  .card {
    width: 400px;
  }
  .container-departamento-ciudad {
    display: flex;
    flex-direction: column;
  }
  .container-departamento-ciudad h3, .container-departamento-ciudad > div {
      margin-left: auto;
      margin-right: auto;
  }
  .flex-container {
    justify-content: space-around;
  }
}

@media (max-width: 500px) {
  /** General styles **/
  h3, h5, p, span, a {
    font-size: 0.7em;
  }
  .title-category {
    font-size: 1.2em;
    font-weight: bold;
  }
  /** /General styles **/
  .card {
    width: 350px;
  }
  .container {
    width: 100%;
  }
  .logo-alacuenta {
    padding: 0 5px;
  }
  .logo-alacuenta > img {
    height: 30px;
    width: 30px;
  }
  .shopping-car-element > .content-left img.product-image {
    border-radius: 0;
    width: 60px;
    height: 45px;
    margin-top: 10px;
    margin-left: 10px;
  }
  .shopping-car-element {
    margin: 0 auto 10px;
    width: 350px;
  }

  h1.title-app {
    font-size: 1.1em;
    margin: 15px 0;
  }
  .logo-alacuenta {
    padding-top: 5px;
  }
  .title-app.subtitle {
    font-size: 0.7em;
    margin-top: 25px;
  }

  .price-text-white.MuiTypography-h5 {
    font-size: 1em;
    margin-right: 10px;
  }
}

@media (max-width: 360px) {
  h1.title-app {
    font-size: 0.8em;
    margin: 18px;
  }
  .shopping-car-element {
    margin: 0 auto 10px;
    width: 300px;
  }
  .price-text-white.MuiTypography-h5 {
    font-size: 1em;
    padding-top: 8px;
  }
}
